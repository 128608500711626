import bread from '../../assets/images/bread-bf.png';
import fruit from '../../assets/images/fruit-bucketjpeg.jpeg';
import salad from '../../assets/images/salad.jpeg';
import fries from '../../assets/images/french-fries.jpeg';

export const menuItems = [
    {
      "category": "Coffee",
      "items": [
        {
          "name": "Cappuccino",
          "price": 3.99,
          "description": "Espresso mixed with steamed milk foam",
          "image": bread,
          "tags": ["gluten free"]
        },
        {
          "name": "Latte",
          "price": 4.49,
          "description": "Espresso mixed with steamed milk",
          "image":salad,
          "tags": []
        },
        {
          "name": "Americano",
          "price": 3.49,
          "description": "Espresso diluted with hot water",
          "image": fries,
          "tags": []
        }
      ]
    },
    {
      "category": "Pastry",
      "items": [
        {
          "name": "Croissant",
          "price": 2.49,
          "description": "Buttery, flaky pastry",
          "image":bread,
          "tags": []
        },
        {
          "name": "Danish",
          "price": 2.99,
          "description": "Sweet, layered pastry filled with fruit or cream",
          "image": fries,
          "tags": []
        }
      ]
    },
    {
      "category": "Brunch",
      "items": [
        {
          "name": "Avocado Toast",
          "price": 7.99,
          "description": "Sourdough toast topped with mashed avocado",
          "image": bread,
          "tags": ["gluten free", "sugar free"]
        },
        {
          "name": "Eggs Benedict",
          "price": 9.99,
          "description": "Poached eggs served on an English muffin with ham and hollandaise sauce",
          "image": fries,
          "tags": []
        }
      ]
    },
    {
      "category": "Dessert",
      "items": [
        {
          "name": "Chocolate Chip Cookie",
          "price": 1.99,
          "description": "Soft and chewy cookie with chocolate chips",
          "image": salad,
          "tags": []
        },
        {
          "name": "Cheesecake",
          "price": 5.99,
          "description": "Rich, creamy cheesecake with a graham cracker crust",
          "image": fries,
          "tags": []
        }
      ]
    }
]

export const menuCategories = menuItems.map(item=>item.category);

export const specials = [
  {
    name: "Date Night",
    description: "Feeling Chocolate, Mojito and pasta",
    image:  fries
  },
  {
    name: "All Alone",
    description: "Luch bhi kha lunga",
    image:  salad
  },
  {
    name: "Its Gym Cheat Day",
    description: "Only Oil Pleas",
    image:  bread
  },
  {
    name: "Its New Year Resolution",
    description: "Let me follow for one month",
    image:  fruit
  }
]