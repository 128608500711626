import { createContext, useContext } from 'react';

export const REFRESH_TOKEN = 'refreshToken';
export const ACCESS_TOKEN = 'accessToken';
export const ACCOUNTID_ID = 'accountid';

export interface IUserContextInterface {
    accessToken: string | null;
    refreshToken: string | null;
    accountid: string | null;
}

export function useAuth() {
    const authContext = createContext<IUserContextInterface>({
        refreshToken: localStorage.getItem(REFRESH_TOKEN),
        accessToken: localStorage.getItem(ACCESS_TOKEN),
        accountid: localStorage.getItem(ACCOUNTID_ID)
    });

    return useContext<IUserContextInterface>(authContext);
}

export function deleteAuth() {
    try {
        localStorage.clear();
    } catch (error) {
        console.error('deleteAuth', error)
    }
}

export function setAuth(refreshToken: string, accessToken: string, accountid: string | null) {
    try {
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        localStorage.setItem(ACCESS_TOKEN, accessToken);

        if (accountid) {
            localStorage.setItem(ACCOUNTID_ID, accountid);
        }
    } catch (error) {
        console.error('setAuth', error);
    }
}

export function setAccountid(accountid: string) {
    localStorage.setItem(ACCOUNTID_ID, accountid);
}