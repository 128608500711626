import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/auth';
import { ERoutesConfig } from '../../routing/path';

export const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const user = useAuth()
  const location = useLocation();

  if (!user.accessToken || user.accessToken === null) {
    return <Navigate to={ERoutesConfig.LOGIN} state={{ from: location }} replace />;
  }

  return children;
};