import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Chip, Typography } from '@mui/material';

interface MenuItem {
  name: string;
  description: string;
  price: number;
  image: string;
  tags: string[];
}

interface MenuItemCardProps {
  item: MenuItem;
}

const MenuItemCard: React.FC<MenuItemCardProps> = ({ item }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="100"
          image={item.image}
          alt={item.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {item.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {item.description}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Price: ₹{item.price.toFixed(2)}
          </Typography>
          <div>
            {item.tags.map((tag, index) => (
              <Chip key={index} size='small' label={tag} variant="outlined" color="primary" />
            ))}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MenuItemCard;
