import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ForgotPass from '../components/auth.page/forgotpass';
import SignInComponent from '../components/auth.page/signin';
import SignUpComponent from '../components/auth.page/signup';
import { Link } from 'react-router-dom';
import { ERoutesConfig } from '../routing/path';

const srcLinks = {
    bgImageLink: 'https://media.istockphoto.com/id/1433211776/photo/woman-silhouette-in-front-of-the-ocean-at-sunset.webp?b=1&s=170667a&w=0&k=20&c=xGzw_4wTjK0uKJLS42lR7xPsD7tGRTrx-mWBl0ylih4='
}

export default function AuthLayout(props: { auth: 'signin' | 'signup' | 'passreset' }) {
    const [auth, setAuth] = React.useState<'signin' | 'signup' | 'passreset'>(props.auth);

    function fetchAuthAction() {
        switch (auth) {
            case 'signin': {
                return < SignInComponent />
            }
            case 'signup': {
                return <SignUpComponent callback={setAuth} />
            }
            case 'passreset': {
                return <ForgotPass />
            }
        }
    }

    return (
        <Grid
            container
            component='main'
            sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${srcLinks.bgImageLink})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
            >
                <Link
                    to={ERoutesConfig.ROOT}
                    replace = {true}>
                    <IconButton
                        aria-label="close"

                        onClick={() => {
                            // Handle close button click here
                        }}
                        sx={{
                            position: 'absolute',
                            right: 8, // Adjust as needed
                            top: 8, // Adjust as needed
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Link>
                {fetchAuthAction()}
            </Grid>
        </Grid>
    );
}
