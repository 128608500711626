import React from 'react';
import { Card, CardContent, CardMedia, Typography, Link, CardHeader } from '@mui/material';
import orderScheduleLogo from '../../assets/images/order.scheduling.logo.jpeg';
import { useStyles } from '../style';
import { getTransaltion } from '../../utils/translations';
import { useAppDispatch } from '../../hooks/reduces';
import { changeFragment } from '../../redux/fragement.reducer';
import { EModule } from '../../config/module';

const imageHeight = '140';

const OrderSchedulingCard: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const handleNavigationChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeFragment({
      fragment: newValue
    }));
  };

  return (
    <Card className={classes.explore_menu_root}>
      <CardHeader
        title={getTransaltion('TXT_ORDER_SCHEDULE_TITLE')}
        sx={{
          textAlign: 'left'
        }}
      />
      <CardMedia
        component="img"
        alt={getTransaltion('TXT_ORDER_SCHEDULE_TITLE')}
        height={imageHeight}
        image={orderScheduleLogo}
        className="card-image"
      />
      <CardContent className={classes.card}>
        <Typography
          variant="body2"
          className={classes.paragraph}
        >
          {getTransaltion('TXT_ORDER_SCHEDULE_DESCRIPTION')}
        </Typography>

        <Link component="button"
          variant="body2"
          onClick={(event) => {
            handleNavigationChange(event, EModule.MENU);
          }}
        >
          {getTransaltion('TXT_KNOW_MORE')}
        </Link>
      </CardContent>
    </Card>
  );
};

export default OrderSchedulingCard;
