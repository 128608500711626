import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, CssBaseline, Paper } from '@mui/material';
import PopupExample from '../components/popup/popup';
import { EModule } from '../config/module';
import { useAppDispatch, useAppSelector } from '../hooks/reduces';
import { changeFragment } from '../redux/fragement.reducer';
import { bottomNavRoutes } from '../routing/routes';

const BaseBottomLayout: React.FC<{ subpage: EModule }> = ({ subpage }) => {
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const { fragment: payload } = useAppSelector((state) => state.fragmentSlice);

  const handleNavigationChange = (event: React.SyntheticEvent, newValue: number) => {
     dispatch(changeFragment({
      fragment: newValue
    }));
  };

   useEffect(() => {
    const isWelcomeShown = localStorage.getItem('welcomeShown');

    if (!isWelcomeShown) {
      setShowPopup(true);
      localStorage.setItem('welcomeShown', 'true');
    }
  }, []);

  useEffect(() => {
    dispatch(changeFragment({
      fragment: subpage
    }));
  }, [subpage]);

  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      {bottomNavRoutes.map(({ Component }, index) => (
        <React.Fragment key={index}>
          {payload === index && (
            Component
          )}
        </React.Fragment>
      ))}
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0
        }}
        elevation={3}>

        <PopupExample openclose={showPopup} />
        <BottomNavigation
          showLabels
          value={payload}
          onChange={handleNavigationChange}
        >
          {bottomNavRoutes.map(({ label, Icon }, index) => (
            <BottomNavigationAction key={index} label={label} icon=<Icon/> />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  );

}

export default React.memo(BaseBottomLayout);