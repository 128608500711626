import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MySnackPropsType } from '../components/snackbar';

const snackBarSlice = createSlice<
    MySnackPropsType,
    { showSnackBar: (state: MySnackPropsType, action: PayloadAction<MySnackPropsType>) => void; },
    'snackbarprop'>({
        name: 'snackbarprop',
        initialState: {
            message: '',
            type: 'info',
            open: false
        },
        reducers: {
            showSnackBar: (state: MySnackPropsType, action: PayloadAction<MySnackPropsType>) => {
               state.message = action.payload.message;
                state.open = action.payload.open;
                state.type = action.payload.type;
            }
        }
    });

export const { showSnackBar } = snackBarSlice.actions;
const snackBarReduces = snackBarSlice.reducer

export default snackBarReduces;