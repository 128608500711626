import * as React from 'react';
import SlidingCards from '../../components/landing.page/slider-container';
import FindUsLayout from '../../components/landing.page/findus';
import { MyToolbar } from '../../components/landing.page/appbar';
import OrderScheduling from '../../components/landing.page/order.scheduling';
import ExploreMenuCard from '../../components/landing.page/explore.menu';

const mainLayoutStyle = {
    margin: '8px',
};

export const FMTruckInfo = () => {
    return (
        <div className='inline'
            style={mainLayoutStyle}
        >
            <MyToolbar />
            <ExploreMenuCard />
            <SlidingCards />
            <OrderScheduling />
            <FindUsLayout />
        </div>)
}
