const translation: { [key: string]: string } = {
  TXT_UI_SPECIAL : 'Special Image',
  TXT_DISCOVER_MENU : 'Discover Our Specials',
  TXT_VIEW : 'View Menu',
  TXT_KNOW_MORE: 'Know More',
  TXT_DISCOVER_MENU_DES: 'Explore our mouthwatering specials crafted by our talented chefs. From seasonal delights to signature dishes, there\'s something for everyone.',
  TXT_ORDER_SCHEDULE_TITLE: 'Convenient Order Scheduling',
  TXT_ORDER_SCHEDULE_DESCRIPTION: 'Plan your meals ahead! Use our order scheduling feature to choose the date and time for your meal orders. Whether it\'s a special occasion or your regular dining time, we\'ve got you covered.',
  TXT_FIND_US_TITLE: 'Where to Find Us',
  TXT_OFFERING_TITLE: 'We Cater Everyone',
  TXT_OFFERING_DESCRIPTION: 'Whether you\'re hosting a small gathering or a grand celebration, our catering services will make your event unforgettable.'
};

export const getTransaltion =  (identifier: string)=>{
  return translation[identifier] ?? identifier;
}