import { Container } from "@mui/system";
import React from "react";
import foodtruck from '../assets/images/poster.jpg';

export const LivePage: React.FC = () => {

  return (
    <Container
      sx={{
        position: 'relative',
        height: '98vh',
        width: '100%',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${foodtruck})`,
        backgroundSize: 'contain', // Ensure the entire background image is visible
        backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
        backgroundPosition: 'center', // Center the background image within the container
      }}
    >
    </Container>
  );
}