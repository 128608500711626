import React from 'react';
import { Card, CardContent, CardMedia, Typography, Link, CardHeader } from '@mui/material';
import specialsImage from '../../assets/images/special-menu.jpeg'; // adjust the path as needed
import { useStyles } from '../style';
import { getTransaltion } from '../../utils/translations';
import { EModule } from '../../config/module';
import { useAppDispatch } from '../../hooks/reduces';
import { changeFragment } from '../../redux/fragement.reducer';

const imageHeight = '140';

const ExploreMenuCard: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleNavigationChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeFragment({
      fragment: newValue
    }));
  };

  return (
    <Card className={classes.explore_menu_root}>
      <CardHeader
        title={getTransaltion('TXT_DISCOVER_MENU')}
        sx={{
          textAlign: 'left'
        }}
      />
      <CardMedia
        component="img"
        alt={getTransaltion('TXT_DISCOVER_MENU_DES')}
        height={imageHeight}
        image={specialsImage}
        className="card-image"
      />
      <CardContent className={classes.card}>
        <Typography
          variant="body2"
          className={classes.paragraph}
        >
          {getTransaltion('TXT_DISCOVER_MENU_DES')}
        </Typography>
        <Link component="button"
          variant="body2"
          onClick={(event) => {
            handleNavigationChange(event, EModule.MENU);
          }}
        >
          {getTransaltion('TXT_VIEW')}
        </Link>
      </CardContent>
    </Card>
  );
};

export default ExploreMenuCard;
