import React from 'react';
import { useForm } from 'react-hook-form';
import { HttpStatusCode } from 'axios';
import { Link, Box, Avatar, Typography, TextField, FormControlLabel, Checkbox, Button, Grid } from '@mui/material';
import { LockPersonOutlined } from '@mui/icons-material';
import { useAppDispatch } from '../../hooks/reduces';
import { showbackDrop } from '../../redux/backdrop.reducer';
import { ERoutesConfig } from '../../routing/path';
import { CopyrightComponent } from './copyright';
import axiosInstance from '../../services/axios';
import { EAPIEndPoints } from '../../services/endpoints';
import { APIResponse } from '../../services/interface';
import { getTransaltion } from '../../utils/translations';
import { regEx } from '../../config/regEx';

interface ISignUpForm {
    email: string,
    firstname: string,
    middlename: string,
    lastname: string,
    mobile: string,
    userpassword: string,
    usercpassword: string,
    address: string,
}

export default function SignUpComponent(props: { callback: CallableFunction }) {
    const { register, watch, formState, handleSubmit } = useForm<ISignUpForm>({
        defaultValues: {
        }
    });
    const { errors } = formState;
    const dispatch = useAppDispatch();

    const onSubmit = async (data: ISignUpForm) => {
        dispatch(showbackDrop({ open: true }));
        try {
            const accountResponse = await axiosInstance.post<APIResponse>(EAPIEndPoints.REGISTER, {
                ...data,
                productplanid: 1,
                accounttypeid: 1,

            });

            if (accountResponse.data.statusCode === HttpStatusCode.Ok) {
                props.callback('signin')
            } else {
                //show toast
            }
            dispatch(showbackDrop({ open: false }));
        } catch (error) {
            dispatch(showbackDrop({ open: false }));
        }
    }

    return (<Box
        sx={{
            my: 4,
            mx: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar
            sx={{
                m: 1,
                bgcolor: 'secondary.main'
            }}>
            <LockPersonOutlined />
        </Avatar>
        <Typography
            component='h1'
            variant='h5'>
            {getTransaltion('TXT_SIGN_UP')}
        </Typography>
        <Box
            component='form'
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{
                mt: 1, mb: 0
            }}
        >
            <TextField
                margin='normal'
                required
                fullWidth
                id='firstname'
                label={getTransaltion('TXT_FIRST_NAME')}
                autoComplete='first name'
                inputProps={{ maxLength: 50, type: 'string' }}
                helperText={errors.firstname?.message}
                error={!!errors.firstname}
                {
                ...register('firstname', {
                    required: getTransaltion('TXT_REQUIRED'),
                })
                }

            />
            <TextField
                margin='normal'
                fullWidth
                id='middlename'
                label={getTransaltion('TXT_MIDDLE_NAME')}
                autoComplete='middle name'
                inputProps={{ maxLength: 50, type: 'string' }}
                helperText={errors.middlename?.message}
                error={!!errors.middlename}
                {
                ...register('middlename')
                }

            />
            <TextField
                margin='normal'
                required
                fullWidth
                id='lastname'
                label={getTransaltion('TXT_LAST_NAME')}
                autoComplete='last name'
                inputProps={{ maxLength: 50, type: 'string' }}
                helperText={errors.lastname?.message}
                error={!!errors.middlename}
                {
                ...register('lastname', {
                    required: getTransaltion('TXT_REQUIRED'),
                })
                }
            />
            <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label={getTransaltion('TXT_EMAIL')}
                autoComplete='email'
                inputProps={{ maxLength: 50, type: 'string' }}
                helperText={errors.email?.message}
                error={!!errors.email}
                {
                ...register('email', {
                    required: getTransaltion('TXT_REQUIRED'),
                    pattern: {
                        value: regEx.emailRegEx,
                        message: getTransaltion('TXT_INVALID_EMAIL')
                    }
                })
                }
            />
            <TextField
                margin='normal'
                required
                fullWidth
                id='mobile'
                label={getTransaltion('TXT_MOBILE')}
                autoComplete='mobile'
                inputProps={{ maxLength: 10, type: 'number' }}
                helperText={errors.mobile?.message}
                error={!!errors.mobile}
                {
                ...register('mobile', {
                    required: getTransaltion('TXT_REQUIRED'),
                })
                }
            />
            <TextField
                margin='normal'
                required
                fullWidth
                id='Addrss'
                label={getTransaltion('TXT_ADDRESS')}
                autoComplete='address'
                inputProps={{ maxLength: 200, type: 'string' }}
                helperText={errors.address?.message}
                error={!!errors.address}
                {
                ...register('address', {
                    required: getTransaltion('TXT_REQUIRED'),
                })
                }
            />
            <TextField
                margin='normal'
                required
                fullWidth
                label={getTransaltion('TXT_PASSWORD')}
                type='password'
                id='password'
                autoComplete='current-password'
                helperText={errors.userpassword?.message}
                error={!!errors.userpassword}
                {
                ...register('userpassword', {
                    required: getTransaltion('TXT_REQUIRED'),
                    pattern: {
                        value: regEx.password,
                        message: getTransaltion('TXT_FORM_PWD_ERROR')
                    },
                })
                }
            />
            <TextField
                margin='normal'
                required
                fullWidth
                label={getTransaltion('TXT_CONFIRM_PASSWORD')}
                type='password'
                id='cpassword'
                autoComplete='current-password'
                error={!!errors.usercpassword}
                helperText={errors.usercpassword?.message}
                {
                ...register('usercpassword', {
                    validate: () => watch().userpassword === watch().usercpassword,
                    required: getTransaltion('TXT_REQUIRED')
                })
                }
            />
            <FormControlLabel
                control={
                    <Checkbox
                        value='remember'
                        color='primary'
                    />
                }
                label={getTransaltion('TXT_REMEMBER')}
            />
            <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
            >
                {getTransaltion('TXT_SIGN_UP')}
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link
                        href={ERoutesConfig.FORGOT_PASS}
                        variant='body2'
                    >
                        {getTransaltion('TXT_FORGOT_PASSWORD')}
                    </Link>
                </Grid>
                <Grid item>
                    <Link
                        href={ERoutesConfig.LOGIN}
                        variant='body2'
                    >
                        {getTransaltion('TXT_SIGNUP_REDIRECT')}
                    </Link>
                </Grid>
            </Grid>
            <CopyrightComponent sx={{ mt: 5 }} />
        </Box>
    </Box>)
}