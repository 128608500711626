import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'center',
      backgroundColor: 'orange',
      margin: '2px',
      maxWidth: '98%',
      marginBottom: '10px',
    },
    title: {
      textAlign: 'left',
      marginTop: '20px',
      fontWeight: 'bold',
      fontSize: '24px',
    },
    paragraph: {
      margin: '2px 2px',
      maxWidth: '500px',
      textAlign: 'left',
    },
    card: {
      width: '300',
      height: '200',
      margin: '0 auto',
      marginBottom: '10px',
      borderRadius: '2px',
    },
    explore_menu_root: {
      textAlign: 'center',
      backgroundColor: 'orange',
      margin: '2px',
      maxWidth: '98%',
      marginBottom: '10px',
    },
    dialog_root: {
      textAlign: 'center',
      backgroundColor: 'orange',
      margin: '2px',
      maxWidth: '98%',
      marginBottom: '10px',
    },
  }));