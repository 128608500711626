/* eslint-disable no-console */
import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { bottomNavRoutes } from './routes';
import { UnprotectedRoute } from '../components/auth/unprotected.routing';
import { ERoutesConfig } from './path';
import BaseLayout from '../pages/base.layout';
import { EModule } from '../config/module';
import AuthLayout from '../pages/auth.layout';
import { ProtectedRoute } from '../components/auth/protected.routing';
import { MenuTable } from '../components/admin/menu.crud';
// import FoodTruckCountdown from '../pages/coming.soon';
import { LivePage } from '../pages/live.page';

const DebugRouter = ({ children, text }: { children: JSX.Element, text?: string }) => {
  const location = useLocation()
  if (process.env.REACT_APP_ENV === 'development') {
    console.log(
      `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
    )
    console.log(
      text,
    )
  }

  return children
}

const routes = <BrowserRouter>
  <Routes>
    {
      bottomNavRoutes.map(route => {
        return (

          <Route
            key={route.index}
            path={route.path}
            element={<DebugRouter text={route.path}
              key={route.index}>
              {route.security === 'unprotected' ?
                route.subpage
                :
                <ProtectedRoute>
                  {route.subpage}
                </ProtectedRoute>}
            </DebugRouter>
            }
          />


        )
      })
    }
    <Route path={ERoutesConfig.LOGIN}
      element={
        <DebugRouter text={ERoutesConfig.LOGIN}>
          <UnprotectedRoute>
            <AuthLayout auth='signin' />
          </UnprotectedRoute>
        </DebugRouter>}
    />
    <Route path={ERoutesConfig.SIGNUP}
      element={
        <DebugRouter text={ERoutesConfig.SIGNUP}>
          <UnprotectedRoute>
            <AuthLayout auth='signup' />
          </UnprotectedRoute>
        </DebugRouter>}
    />
    <Route path={ERoutesConfig.FORGOT_PASS}
      element={
        <DebugRouter text={ERoutesConfig.FORGOT_PASS}>
          <UnprotectedRoute>
            <AuthLayout auth='passreset' />
          </UnprotectedRoute>
        </DebugRouter>}
    />
    <Route path={ERoutesConfig.UNDEFINED}
      element={
        <DebugRouter text={ERoutesConfig.UNDEFINED}>
          <UnprotectedRoute>
            <BaseLayout subpage={EModule.ORDERS} />
          </UnprotectedRoute>
        </DebugRouter>}
    />
    <Route path={ERoutesConfig.TEST}
      element={
        <DebugRouter text={ERoutesConfig.TEST}>
          <UnprotectedRoute>
            <MenuTable />
          </UnprotectedRoute>
        </DebugRouter>}
    />
  </Routes>
</BrowserRouter >

export const AppRouting = () => {

  console.log(process.env)

  if (process.env.REACT_APP_ENV === 'development') {
    return routes;
  } else {
    return <BrowserRouter>
      <Routes>
        <Route path={ERoutesConfig.UNDEFINED}
          element={
            <DebugRouter text={ERoutesConfig.UNDEFINED}>
              <UnprotectedRoute>
                <LivePage />
              </UnprotectedRoute>
            </DebugRouter>}
        />
      </Routes>
    </BrowserRouter >;
  }
}