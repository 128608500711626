import React, { useState, useRef } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton, Button } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';

interface ItemInterface {
  name: string,
  description: string,
  id: number
}

const MenuItemList: React.FC<{ menuItems: ItemInterface[], cb: CallableFunction }> = ({ menuItems, cb }) => {
  const [editableItemId, setEditableItemId] = useState<number | null>(null);
  const [editedItem, setEditedItem] = useState<ItemInterface | null>(null);
  const newRowRef = useRef<HTMLTableRowElement>(null);

  const handleEdit = (id: number) => {
    setEditableItemId(id);
    const itemToEdit = menuItems.find(item => item.id === id);
    if (itemToEdit) {
      setEditedItem(itemToEdit);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (editedItem) {
      setEditedItem({ ...editedItem, [name]: value });
    }
  };

  const handleSave = () => {
    // Perform save operation (e.g., API call);

    cb(editedItem);
    setEditableItemId(null);
    setEditedItem(null);
  };

  const handleAddRow = () => {
    const newItem: ItemInterface = { id: menuItems.length + 1, name: '', description: '' };
    menuItems.push(newItem);
    setEditableItemId(newItem.id);
    setEditedItem(newItem);
    setTimeout(() => {
      if (newRowRef.current) {
        newRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        newRowRef.current.querySelector('input')?.focus();
      }
    }, 100); // Delay required to ensure proper focus after rendering
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {menuItems.map((menuItem) => (
            <TableRow key={menuItem.id}>
              <TableCell>{menuItem.id}</TableCell>
              <TableCell>
                {editableItemId === menuItem.id ? (
                  <TextField
                    name="name"
                    value={editedItem?.name || ''}
                    onChange={handleChange}
                  />
                ) : (
                  menuItem.name
                )}
              </TableCell>
              <TableCell>
                {editableItemId === menuItem.id ? (
                  <TextField
                    name="description"
                    value={editedItem?.description || ''}
                    onChange={handleChange}
                  />
                ) : (
                  menuItem.description
                )}
              </TableCell>
              <TableCell>
                {editableItemId === menuItem.id ? (
                  <IconButton onClick={handleSave}>
                    <Save />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleEdit(menuItem.id)}>
                    <Edit />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow ref={newRowRef}>
            <TableCell colSpan={4}>
              <Button variant="outlined" color="primary" onClick={handleAddRow}>Add Item</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const MenuTable = () => {
  const menuItems: ItemInterface[] = [
    { id: 1, name: 'Item 1', description: 'Description 1' },
    { id: 2, name: 'Item 2', description: 'Description 2' },
    // Add more menu items as needed
  ];


  const [menu, setmenu] = useState<ItemInterface[]>(menuItems)

  const cb = (item: ItemInterface) => {
    const idexist = menu.find(i => i.id === item.id);
    if (idexist) {
      const newmwnu = menu.map(i => {
        if (i.id === item.id) {
          return item;
        } else {
          return i;
        }
      });

      setmenu(newmwnu);
    } else {
      setmenu(value => [...value, item]);
    }

  }

  return (
    <div>
      <h1>Menu Items</h1>
      <MenuItemList menuItems={menu} cb={cb} />
    </div>
  );
};
