export enum ERoutesConfig {
  /** protected routes */
  ROOT = '/',
  CART = '/cart',
  // MENU = '/menu',
  TRUCK = '/truck',
  PROFILE = '/profile',
  // TEACHER_ADD = '/teacher/add',
  // SETTING = '/setting',
  // CLASS = '/class',
  // STUDENT = '/student',
  UNDEFINED = '/*',

  // /** unprotected routes */
  LOGIN = '/login',
  SIGNUP = '/signup',
  FORGOT_PASS = '/password-rest',
  MENU = 'menu',

  // /** landing pages */
  // ACCOUNT_VERIFIED = '/auth/verify/account/:id',


  // /** test routes */
  TEST = '/test'
}
