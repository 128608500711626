
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CustomBackDropProp } from '../components/backdrop';

const backDropSlice = createSlice<
    CustomBackDropProp,
    { showbackDrop: (state: CustomBackDropProp, action: PayloadAction<CustomBackDropProp>) => void; },
    'backDropprop'>({
        name: 'backDropprop',
        initialState: {
            open: false
        },
        reducers: {
            showbackDrop: (state: CustomBackDropProp, action: PayloadAction<CustomBackDropProp>) => {
                state.open = action.payload.open;
            }
        }
    });

export const { showbackDrop } = backDropSlice.actions;
const backDropReduces = backDropSlice.reducer

export default backDropReduces;