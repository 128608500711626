import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ERoutesConfig } from '../../routing/path';
import { useAuth } from '../../utils/auth';

export const UnprotectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const user = useAuth()
  const nav = useNavigate()
  
  useEffect(() => {
    if (user.accessToken) {
      nav(ERoutesConfig.ROOT);
    }
  }, []);

  return children;
};