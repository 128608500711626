/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ACCESS_TOKEN, ACCOUNTID_ID, setAuth } from "../utils/auth";
import { APIResponse } from "./interface";
import { EAPIEndPoints } from "./endpoints";
import { EnvConfigs } from "../config/env";
// import { EAPIEndPoints, EnvConfigs } from "../config";
// import { ACCESS_TOKEN, ACCOUNTID_ID, setAuth } from "../utils";
// import { APIResponse } from "../interfaces-1";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: { [key: string]: any }
}

const axiosInstance = axios.create({
  baseURL: EnvConfigs.apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
    _retry: false
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const accountid = localStorage.getItem(ACCOUNTID_ID);
    if (accountid) {
      config.headers.accountid = accountid;
    }

    return config;
  },
  (error) => {
    console.error("AXIOS REQUEST", error);
  }
);

const axiosErrorHandlerFunction = async function (error: AxiosError): Promise<APIResponse> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = error.response as any;
  const originalRequest = error.config as CustomAxiosRequestConfig | undefined;

  if (originalRequest) {
    try {
      if (error.code === "ERR_NETWORK") {
        if (!originalRequest.headers._retry) {
          originalRequest.headers._retry = true;
          return await axiosInstance(originalRequest);
        } else {
          return response;
        }
      } else if (error.response && error.response.status === 401 && !originalRequest.headers._retry) {
        originalRequest.headers._retry = true;
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          const { data } = await axios.post(EAPIEndPoints.GENERATE_ACCESS_TOKEN, {
            refresh_token: refreshToken,
          });
          setAuth(data.accessToken, data.refreshToken, data.headers.accountid);

          if (data.status === 200) {
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
            return await axiosInstance(originalRequest);
          } else {
            return response;
          }
        } else {
          return response;
        }
      } else {
        response.message = 'Something unexpected happened';
        return response;
      }
    } catch (error) {
      console.error("AXIOS ERROR HANDLER", error);
      return response;
    }
  } else {
    console.error("AXIOS CORS ERROR", error);
    return response;
  }
}

axiosInstance.interceptors.response.use(
  (response) => response,
  axiosErrorHandlerFunction
);

export default axiosInstance;
