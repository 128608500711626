import React from "react";
import { EModule } from "../config/module";
import { Add, FireTruck, FoodBank, Person, Search, SvgIconComponent } from '@mui/icons-material';
import { ERoutesConfig } from "./path";
import BottomNav from '../pages/base.layout';
import { FMTruckInfo } from "../pages/fragments/fm.truck.info.";
import { MenuFragment } from "../pages/fragments/fm.menu";
import { UserFragment } from '../pages/fragments/fm.user';
import { ProtectedRoute } from "../components/auth/protected.routing";
import { MenuTable } from "../components/admin/menu.crud";

export interface RoutesInfoInterface {
    index: EModule;
    label: string;
    subpage: JSX.Element;
    Component: JSX.Element;
    Icon: SvgIconComponent;
    path: ERoutesConfig;
    security: 'protected' | 'unprotected',
}

const routes: RoutesInfoInterface[] = [
    {
        index: EModule.TRUCK,
        label: 'Nutrino',
        subpage: <BottomNav subpage={EModule.TRUCK} />,
        Component: <FMTruckInfo />,
        path: ERoutesConfig.TRUCK,
        Icon: FireTruck,
        security: 'unprotected',
    },
    {
        index: EModule.MENU,
        label: 'Menu',
        subpage: <BottomNav subpage={EModule.MENU} />,
        Component: <MenuFragment />,
        path: ERoutesConfig.ROOT,
        Icon: FoodBank,
        security: 'unprotected',
    },
    {
        index: EModule.PROFILE,
        label: 'Profile',
        subpage: <BottomNav subpage={EModule.PROFILE} />,
        Component: <ProtectedRoute><UserFragment /></ProtectedRoute>,
        path: ERoutesConfig.PROFILE,
        Icon: Person,
        security: 'protected',
    },
    {
        index: EModule.ORDERS,
        label: 'Search',
        Icon: Search,
        subpage: <BottomNav subpage={EModule.PROFILE} />,
        Component: <ProtectedRoute><div>Search component</div></ProtectedRoute>,
        path: ERoutesConfig.CART,
        security: 'protected',
    },
];

const adminRoutes: RoutesInfoInterface[] = [
    {
        index: EModule.TRUCK,
        label: 'Nutrino',
        subpage: <BottomNav subpage={EModule.TRUCK} />,
        Component: <FMTruckInfo />,
        path: ERoutesConfig.TRUCK,
        Icon: FireTruck,
        security: 'unprotected',
    },
    {
        index: EModule.MENU,
        label: 'Menu',
        subpage: <BottomNav subpage={EModule.MENU} />,
        Component: <MenuFragment />,
        path: ERoutesConfig.ROOT,
        Icon: FoodBank,
        security: 'unprotected',
    },
    {
         index: EModule.ADMIN,
        label: 'ADMIN',
        subpage: <BottomNav subpage={EModule.ADMIN} />,
        Component: <MenuTable />,
        path: ERoutesConfig.MENU,
        Icon: Add,
        security: 'unprotected',
    }
];

const getRoutes = () => {    
    switch (process.env.REACT_APP_PRODUCT_TYPE) {
        case 'ADMIN': {
            return adminRoutes;
        }
        default: {
            return routes;
        }
    }

}

export const bottomNavRoutes = getRoutes();