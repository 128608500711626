import React from 'react';
import { Typography, TypographyProps, Link } from '@mui/material';
import { EnvConfigs } from '../../config/env';
import { getTransaltion } from '../../utils/translations';

export function CopyrightComponent(props: TypographyProps) {
    return (
        <Typography
            variant='body2'
            color='text.secondary'
            align='center' {...props}
        >
            {getTransaltion('TXT_COPYRIGHT')}

            <Link color='inherit'
                href={EnvConfigs.copyrightLink}
            >
                {EnvConfigs.appname}
            </Link>{' '}

            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}