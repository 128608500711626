import { combineReducers, configureStore } from "@reduxjs/toolkit";
import backDropReduces from "../redux/backdrop.reducer";
import snackBarSlice from '../redux/snackbar.reducer';
import fragmentSlice from '../redux/fragement.reducer';

const rootReducers = combineReducers({
    snackBarSlice,
    backDropReduces,
    fragmentSlice
})

export const store = configureStore({
    reducer: rootReducers
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;