import React, { RefObject, createRef } from 'react';
import { AppBar, Typography, Button, Card, CardMedia, CardContent, Box, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { menuCategories, menuItems, specials } from '../../testing/pages/local.data';
import Carousel from 'react-material-ui-carousel';
import MenuItemCard from '../../components/menu.page/item.card';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

interface CategoryRef {
  [key: string]: RefObject<HTMLDivElement>;
}

export const MenuFragment = () => {

  const categoryRefs: CategoryRef = menuItems.reduce((refs, item) => {
    refs[item.category] = createRef();
    return refs;
  }, {} as CategoryRef);

  const handleCategoryClick = (category: string) => {
    // Scroll to the category section when the category button is clicked
    categoryRefs[category].current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search menu items"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
      </AppBar>
      <Box sx={{ p: 1 }}>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
          gap: 1,
          mt: 2,
          '& > button': {
            width: '100%',
          },
          '@media (min-width: 600px)': {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
        }}>
          {
            menuCategories.map(category => {
              return <Button
                key={category}
                variant="contained"
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </Button>
            })
          }
        </Box>
        <Carousel autoPlay={true} animation="slide" interval={4000}>
          {specials.map((card, index) => (
            <Card
              key={index}
              sx={{
                minWidth: '300px',
                width: '100%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                margin: '2px 2px',
                backgroundColor: index % 2 ? 'lightblue' : 'lightred',
                transition: 'opacity 0.3s ease-in-out',
              }}
            >
              <CardMedia
                component="img"
                alt="Order Scheduling"
                height="140"
                width="18%"
                image={card.image}>
              </CardMedia>
              <CardContent>
                <Typography variant="h5" component="div">
                  {card.name}
                </Typography>
                <Typography
                  variant="body2"
                /* color="text.secondary" */
                >
                  {card.description}
                </Typography>
              </CardContent>
              {/* <CardActions>
        <Button size="small">{card}</Button>
      </CardActions> */}
            </Card>
          ))}
        </Carousel>
        <Box sx={{ mt: 2 }}>
          <Box sx={{ p: 1 }}>

            {menuItems.map(category => {
              return (<Typography key={category.category} gutterBottom variant="h6" component="div" ref={categoryRefs[category.category]}>
                {category.category}
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
                  gap: 1,
                  mt: 2,
                  '& > button': {
                    width: '100%',
                  },
                  '@media (min-width: 600px)': {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  },
                }}>
                  {category.items.map(item => {
                    return <MenuItemCard key={item.name} item={item} />
                  })}
                </Box>
              </Typography>)
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
