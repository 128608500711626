export enum EAPIEndPoints {
  LOGIN = '/api/v1/auth/user/login',
  REGISTER = '/api/v1/auth/school/register',
  VERIFICATION = '/api/v1/auth/school/verification/resend',
  VERIFY = '/api/v1/auth/user/verify',
  REQUEST_PASSWORD_RESET = '/api/v1/auth/password/reset', 
  GENERATE_ACCESS_TOKEN = '/api/v1/auth/user/accesstoken',
  GET_USER_ACCOUNTS = '/api/v1/user/school',
  GET_USER = '/api/v1/user/detail',
  USER = '/api/v1/school/teacher'
}