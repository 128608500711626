import React from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';
import Carousel from "react-material-ui-carousel";
import { useStyles } from '../style';
import orderScheduleLogo from '../../assets/images/order.scheduling.logo.jpeg';
import { getTransaltion } from '../../utils/translations';

const cardData = [
  { title: 'Gym Freak', content: 'Aao Ande khao aur doodh pilo', colour: 'blue', action: 'Explore Gym Specials', nav: '/menu/gym' },
  { title: 'Time For Treat', content: 'Bring My Boys And Gals', colour: 'green', action: 'Explore Party Specials', nav: '/menu/party' },
  { title: 'Its Date Time', content: 'Lets vibe into cold coffee without ice cream', colour: 'red', action: 'Explore Date Specials', nav: '/menu/date' },
];

const imageHeight = '40';
const imageWidth = '18%';

const SlidingCards: React.FC = () => {
  const classes = useStyles();

  return (
    <Card className={classes.explore_menu_root}>
      <CardHeader
        title={getTransaltion('TXT_OFFERING_TITLE')}
        sx={{
          textAlign: 'left'
        }}
      />
      <CardContent className={classes.card}>
        <Typography variant="body2" className={classes.paragraph}>
          {getTransaltion('TXT_OFFERING_DESCRIPTION')}
        </Typography>
        
        <Carousel autoPlay={true} animation="slide" interval={4000}>
          {cardData.map((card, index) => (
            <Card
              key={index}
              sx={{
                minWidth: '300px',
                width: '100%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                margin: '2px 2px',
                backgroundColor: card.colour,
                transition: 'opacity 0.3s ease-in-out',
              }}
            >
              <CardMedia
                component="img"
                alt={getTransaltion('TXT_OFFERING_TITLE')}
                height={imageHeight}
                width={imageWidth}
                image={orderScheduleLogo}
                className="card-image">
              </CardMedia>
              <CardContent>
                <Typography variant="h5" component="div">
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                /* color="text.secondary" */
                >
                  {card.content}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">{card.action}</Button>
              </CardActions>
            </Card>
          ))}
        </Carousel>
      </CardContent>
    </Card>
  );
};

export default SlidingCards;
