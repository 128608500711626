import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ExploreMenuCard from '../landing.page/explore.menu';
import OrderSchedulingCard from '../landing.page/order.scheduling';
import {useStyles} from '../style';

const PopupExample: React.FC<{ openclose: boolean }> = ({ openclose }) => {
  const [open, setOpen] = useState(openclose);
  const classes = useStyles();

  const handlePopupClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullScreen
        open={open}
        onClose={handlePopupClose}
        className={classes.dialog_root}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handlePopupClose}
          aria-label="close"
          sx={{ position: 'absolute', top: 10, right: 30 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Welcome to Nitrino</DialogTitle>
        <DialogContent>
          <ExploreMenuCard />
          <OrderSchedulingCard />

        </DialogContent>

      </Dialog>
    </div>
  );
};

export default PopupExample;