import React from 'react';
import { Link, Box, Avatar, Typography, TextField, FormControlLabel, Checkbox, Button, Grid } from '@mui/material';
import { LockPersonOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/reduces';
import { showbackDrop } from '../../redux/backdrop.reducer';
import { showSnackBar } from '../../redux/snackbar.reducer';
import { ERoutesConfig } from '../../routing/path';
import { setAuth } from '../../utils/auth';
import { CopyrightComponent } from './copyright';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../services/axios';
import { APIResponse } from '../../services/interface';
import { regEx } from '../../config/regEx';
import { EAPIEndPoints } from '../../services/endpoints';
import { getTransaltion } from '../../utils/translations';
import './auth.page.css'

type ILoginType = {
    email: string,
    userpassword: string
}

export default function SignInComponent() {
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const { register, formState, handleSubmit } = useForm<ILoginType>();
    const { errors } = formState;

    const submit = async (formdata: ILoginType) => {
        dispatch(showbackDrop({ open: true }));
        const loginResponse = await axiosInstance.post<APIResponse>(EAPIEndPoints.LOGIN, { ...formdata, clientuniqueid: new Date().toISOString() });

        if (loginResponse.data.error) {
            dispatch(showbackDrop({ open: false }));
            dispatch(showSnackBar({
                message: loginResponse.data.message,
                type: 'error',
                open: true
            }));

        } else {
            setAuth(loginResponse.data.data.refreshToken.token, loginResponse.data.data.session.accesstoken, null);

            const schoolListResponse = await axiosInstance.get<APIResponse>(EAPIEndPoints.GET_USER_ACCOUNTS);
            if (!schoolListResponse.data.error && schoolListResponse.data.data && schoolListResponse.data.data.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const account = schoolListResponse.data.data[0] as any;
                dispatch(showbackDrop({ open: false }));
                dispatch(showSnackBar({
                    message: loginResponse.data.message,
                    type: 'success',
                    open: true
                }));
                setAuth(loginResponse.data.data.refreshToken.token, loginResponse.data.data.session.accesstoken, account.accountid);
                nav(ERoutesConfig.PROFILE);
            } else {
                dispatch(showbackDrop({ open: false }));
                dispatch(showSnackBar({
                    message: schoolListResponse.data.message,
                    type: 'error',
                    open: true
                }));
            }
        }
    };

    return (<Box
        sx={{
            my: 8,
            mx: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar
            sx={{
                m: 1,
                bgcolor: 'secondary.main'
            }}>
            <LockPersonOutlined />
        </Avatar>
        <Typography
            component='h1'
            variant='h5'>
            {getTransaltion('TXT_SIGN_IN')}
        </Typography>
        <Box
            component='form'
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{
                mt: 1, mb: 0
            }}
        >
            <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label={getTransaltion('TXT_EMAIL')}
                autoComplete='email'
                autoFocus
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register('email', {
                    required: getTransaltion('TXT_REQUIRED'),
                    pattern: {
                        value: new RegExp(regEx.emailRegEx),
                        message: getTransaltion('TXT_INVALID_EMAIL')
                    }
                })}
            />
            <TextField
                margin='normal'
                required
                fullWidth
                label={getTransaltion('TXT_PASSWORD')}
                type='password'
                id='userpassword'
                autoComplete='current-password'
                error={!!errors.userpassword}
                helperText={errors.userpassword?.message}
                {...register('userpassword', {
                    required: getTransaltion('TXT_REQUIRED'),
                    pattern: {
                        value: regEx.password,
                        message: getTransaltion('TXT_FORM_PWD_ERROR')
                    }
                })}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        value='remember'
                        color='primary'
                    />
                }
                label={getTransaltion('TXT_REMEMBER')}
            />
            <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
            >
                {getTransaltion('TXT_SIGN_IN')}
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link
                        href={ERoutesConfig.FORGOT_PASS}
                        variant='body2'
                    >
                        {getTransaltion('TXT_FORGOT_PASSWORD')}
                    </Link>
                </Grid>
                <Grid item>
                    <Link
                        href={ERoutesConfig.SIGNUP}
                        variant='body2'
                    >
                        {getTransaltion('TXT_SIGNUP_REDIRECT')}
                    </Link>
                </Grid>
            </Grid>
            <CopyrightComponent sx={{ mt: 5 }} />
        </Box>
    </Box>)
}
