import React from 'react';
import { Provider } from 'react-redux';
import CustomizedBackDrops from './components/backdrop';
import CustomizedSnackbars from './components/snackbar';
import { store } from './hooks/store';
import { AppRouting } from './routing/routing';
import { Box, ThemeProvider } from '@mui/material';
import { defaultTheme } from './theme/theme';

export default function App() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // height: '100vh', // This makes sure the box is centered vertically
                width: '100%', // This makes sure the box is centered horizontally
                overflow: 'clip'
            }}
        ><Box
            sx={{
                width: 700,
            }}
        >
                <Provider store={store}>
                    <ThemeProvider theme={defaultTheme}>
                        <AppRouting />
                        <CustomizedSnackbars />
                        <CustomizedBackDrops />
                    </ThemeProvider>
                </Provider>
            </Box>
        </Box>
    );
}