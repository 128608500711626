import React from 'react';
import { Room } from '@mui/icons-material';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useStyles } from '../style';
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import { getTransaltion } from '../../utils/translations';
import { Loader } from '../loader';

// const apikey = "AIzaSyDZ7Qbdapa-sMHfMxgo5l4sTq4sheWSoIQ";
const apikey = '';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const zoomLevel = 14;

//TODO Account Specfic Data
const center = {
  lat: 21.092989, // Your latitude
  lng: 81.020129  // Your longitude
};

//TODO Account Specfic Data
const address = "123 Main Street, City, Country";


const FindUsLayout = () => {
  const classes = useStyles();
  
  return (
    <Card className={classes.explore_menu_root}>
      <CardHeader
        title={getTransaltion('TXT_FIND_US_TITLE')}
        className={classes.title} />
      <CardContent>
        <Typography variant="body1" gutterBottom className={classes.paragraph}>
          <Room /> {address}
        </Typography>

        <LoadScript
          googleMapsApiKey={apikey}
          loadingElement=<Loader />
          libraries={['places']}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoomLevel}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </CardContent>
    </Card>
  );
};

export default FindUsLayout;
