import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useAppSelector } from '../hooks/reduces';

export type CustomBackDropProp = {
  open: boolean;
}

export default function CustomizedBackDrops() {
  const { open } = useAppSelector((state) => state.backDropReduces);
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
}
