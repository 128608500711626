import React from 'react';
import { Link, Box, Avatar, Typography, TextField, Button, Grid } from '@mui/material';
import { LockResetOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { regEx } from '../../config/regEx';
import { useAppDispatch } from '../../hooks/reduces';
import { showbackDrop } from '../../redux/backdrop.reducer';
import { showSnackBar } from '../../redux/snackbar.reducer';
import { ERoutesConfig } from '../../routing/path';
import axiosInstance from '../../services/axios';
import { EAPIEndPoints } from '../../services/endpoints';
import { APIResponse } from '../../services/interface';
import { CopyrightComponent } from './copyright';
import { getTransaltion } from '../../utils/translations';

type ILoginType = {
    email: string
}

export default function ForgotPass() {
    const nav = useNavigate();
    const dispatch = useAppDispatch();

    const { register, formState, handleSubmit } = useForm<ILoginType>();
    const { errors } = formState;

    const submit = async (formdata: ILoginType) => {
        dispatch(showbackDrop({ open: true }));
        const requestPasswordResponse = await axiosInstance.post<APIResponse>(EAPIEndPoints.REQUEST_PASSWORD_RESET, { ...formdata });

        if (requestPasswordResponse.data.error) {
            dispatch(showbackDrop({ open: false }));
            dispatch(showSnackBar({
                message: requestPasswordResponse.data.message,
                type: 'error',
                open: true
            }));
        } else {
            dispatch(showbackDrop({ open: false }));
            dispatch(showSnackBar({
                message: getTransaltion('TXT_PASSWORD_RESET_MAIL_SENT'),
                type: 'info',
                open: true
            }));
            nav(ERoutesConfig.LOGIN);
        }
    };

    return (<Box
        sx={{
            my: 6,
            mx: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar
            sx={{
                m: 1,
                bgcolor: 'secondary.main'
            }}>
            <LockResetOutlined />
        </Avatar>
        <Typography
            component='h1'
            variant='h5'>
            {getTransaltion('TXT_FORGOT_PASSWORD')}
        </Typography>
        <Typography
            marginTop={2}
            component='h1'
            paragraph={true}
        >
            {getTransaltion('TXT_FORGOT_PASS_MESSAGE')}
        </Typography>
        <Box
            component='form'
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{
                mt: 1, mb: 0
            }}
        >
            <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label={getTransaltion('TXT_EMAIL')}
                autoComplete='email'
                autoFocus
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register('email', {
                    required: getTransaltion('TXT_REQUIRED'),
                    pattern: {
                        value: new RegExp(regEx.emailRegEx),
                        message: getTransaltion('TXT_INVALID_EMAIL')
                    }
                })}
            />
            <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
            >
                {getTransaltion('TXT_RESET_SEND')}
            </Button>
            <Grid container>
                <Grid item>
                    <Link
                        href={ERoutesConfig.SIGNUP}
                        variant='body2'
                    >
                        {getTransaltion('TXT_SIGNUP_REDIRECT')}
                    </Link>
                </Grid>
            </Grid>
            <CopyrightComponent sx={{ mt: 5 }} />
        </Box>
    </Box>)
}
