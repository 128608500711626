import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { showSnackBar } from '../redux/snackbar.reducer';
import Stack from '@mui/material/Stack/Stack';
import { useAppDispatch, useAppSelector } from '../hooks/reduces';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export type MySnackPropsType = {
    type: 'success' | 'warning' | 'error' | 'info',
    duration?: number,
    message: string,
    open: boolean
}

export default function CustomizedSnackbars() {
    const dispatch = useAppDispatch();
    const { message, type, open } = useAppSelector((state) => state.snackBarSlice);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(showSnackBar({
            message: '',
            open: false,
            type: 'info'
        }));
    };

    return (
        <Stack spacing={2}
            sx={{ width: '50%' }}
        >
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={type}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
