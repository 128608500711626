import { PayloadAction, createSlice, Draft } from "@reduxjs/toolkit";
import { EModule } from "../config/module";

export type FragmentProps = {
  fragment: EModule,
}

const FragmentSlice = createSlice({
  name: 'fragmentProp',
  initialState: {
    fragment: EModule.MENU,
  } as FragmentProps,
  reducers: {
    changeFragment: (state: Draft<FragmentProps>, action: PayloadAction<FragmentProps>) => {
      state.fragment = action.payload.fragment;
    }
  }
});

export const { changeFragment } = FragmentSlice.actions;
export default FragmentSlice.reducer;
